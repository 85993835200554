import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { theme } from "../../GlobalStyle"

import photo from "../../images/photo.png"

// old SVGs
import Github from "./../svg/Github.js"
import Linkedin from "./../svg/Linkedin.js"
import Twitter from "./../svg/Twitter.js"
import Youtube from "./../svg/Youtube.js"
import Instagram from "./../svg/Instagram.js"

// new SVGs
// import Linkedin from "../svg/new/Linkedin.js"
// import Github from "../svg/new/Github.js"
// import Twitter from "../svg/new/Twitter.js"
// import Youtube from "../svg/new/Youtube.js"
// import Instagram from "../svg/new/Instagram.js"

const Header = styled.div`
  grid-column: 1/4;

  .largeHeader {
    display: grid;
    grid-template-columns: 1fr 1150px 1fr;
    height: 23rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
    background-color: ${theme.colors.main};
    color: ${theme.colors.maroon};
    font-size: 2.7rem;

    .left {
      grid-column: 1/2;
      background-color: ${theme.colors.main};
    }

    .center {
      grid-column: 2/3;
      display: grid;
      grid-template-columns: 1fr 1fr 1.05fr 0.95fr;
    }

    .introduction {
      grid-column: 1/3;
      margin-top: 2rem;
    }

    .links {
      grid-column: 3/4;
      margin-top: 2rem;

      .link {
        font-size: 1.8rem;
      }
    }

    img {
      grid-column: 4/5;
      max-width: 20rem;
      height: auto;
      margin: 0 auto;
      margin-top: 1.5rem;
    }

    .right {
      grid-column: 3/4;
      background-color: ${theme.colors.main};
    }

    a {
      color: ${theme.colors.maroon};
      text-decoration: none;

      &:hover {
        color: #b23739;
        text-shadow: 0.4px 0.3px red;
      }
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .smallHeader {
    display: none;
    background-color: ${theme.colors.main};
    color: ${theme.colors.maroon};
    font-size: 2.7rem;
    margin-bottom: 3rem;

    img {
      max-width: 15rem;
      display: block;
      height: auto;
      margin: 0 auto;
      margin-top: 1.8rem;
    }

    p {
      /* padding: 0 4rem; */

      @media (max-width: 400px) {
        font-size: 2.2rem;
      }

      @media (max-width: 380px) {
        font-size: 2.1rem;
      }

      @media (max-width: 350px) {
        font-size: 2rem;
      }

      @media (max-width: 330px) {
        font-size: 1.9rem;
      }
    }

    .p1 {
      margin-top: 1.2rem;
      text-align: center;
    }

    .p2 {
      text-align: center;
      @media (max-width: 765px) {
        display: none;
      }
    }

    .p3 {
      text-align: center;
      @media (max-width: 765px) {
        display: none;
      }
    }

    .p12 {
      display: none;
      text-align: center;

      @media (max-width: 765px) {
        display: block;
      }
    }

    .p13 {
      display: none;
      text-align: center;

      @media (max-width: 765px) {
        display: block;
      }
    }

    .p14 {
      display: none;
      text-align: center;

      @media (max-width: 765px) {
        display: block;
      }
    }

    .p15 {
      display: none;
      text-align: center;

      @media (max-width: 765px) {
        display: block;
      }
    }

    .links {
      margin-top: 1rem;
      margin-bottom: 1.45rem;
      text-align: center;
    }

    .introduction {
      /*  */
    }

    a {
      color: ${theme.colors.maroon};
      text-decoration: none;
    }

    @media (max-width: 1200px) {
      display: grid;
    }

    @media (max-width: 560px) {
      font-size: 2.4rem;
    }
  }

  .svgText-ig {
    padding-left: 6px;
  }
`

export default () => (
  <Header>
    {/* LARGE HEADER */}
    <div className="largeHeader">
      <div className="left" />
      <div className="center">
        <div className="introduction">
          <p>Hi, I'm Max 👋</p>
          <p> &nbsp; &nbsp; I'm a Full Stack Web Developer,</p>
          <p> &nbsp; &nbsp; &nbsp; based in SF Bay Area.</p>
          <p>
            {" "}
            &nbsp; &nbsp; &nbsp; &nbsp; Thrilled about all things JavaScript,
          </p>
          <p> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; passionate about teaching</p>
        </div>
        <div className="links">
          <p>
            <strong>Let's Connect !</strong>
          </p>
          <p className="link">
            <a
              href="https://www.linkedin.com/in/max-astapenko/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin height="15px" width="15px" />
              {/* <Linkedin width="16px" height="16px" /> */}
              &nbsp; linkedin.com/in/max-astapenko
            </a>
          </p>
          <p className="link">
            <a
              href="https://github.com/getMaxed"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp; &nbsp;
              <Github height="15px" width="15px" />
              {/* <Github width="16px" height="16px" /> */}
              &nbsp;github.com/getMaxed
            </a>
          </p>
          <p className="link">
            <a
              href="https://twitter.com/getmaxeddd"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp; &nbsp; &nbsp; <Twitter height="15px" width="15px" />{" "}
              {/* &nbsp; &nbsp; &nbsp; */}
              {/* <Twitter width="16px" height="16px" /> */}
              twitter.com/getmaxeddd
            </a>
          </p>
          <p className="link">
            <a
              href="https://www.youtube.com/channel/UCMS8XLydNkkS10qo94Bya4g/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp; &nbsp; &nbsp; &nbsp; <Youtube height="15px" width="15px" />{" "}
              {/* <Youtube width="19px" height="19px" /> */}
              YouTube @getMaxed
            </a>
          </p>
          <p className="link">
            <a
              href="https://www.instagram.com/getmaxeddd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              <Instagram width="15px" height="15px" />
              <span className="svgText-ig">instagram @getmaxeddd</span>
            </a>
          </p>
        </div>
        <img src={photo} alt="photo" />
      </div>
      <div className="right" />
    </div>

    {/* SMALL HEADER */}
    <div className="smallHeader">
      <div className="introduction">
        <img src={photo} alt="photo" />
        <p className="p1">Hi, I'm Max 👋 </p>
        <p className="p2">
          I'm a Full Stack Web Developer, based in SF Bay Area.{" "}
        </p>

        <p className="p3">
          Thrilled about all things JavaScript, passionate about teaching
        </p>
        <p className="p12">I'm a Full Stack Web Developer,</p>
        <p className="p13">based in SF Bay Area.</p>
        <p className="p14">Thrilled about all things JavaScript,</p>
        <p className="p15">passionate about teaching</p>

        <p className="links">
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://www.linkedin.com/in/max-astapenko/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin height="25px" width="25px" />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://github.com/getMaxed"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Github height="25px" width="25px" />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://twitter.com/getmaxeddd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter height="25px" width="25px" />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://www.youtube.com/channel/UCMS8XLydNkkS10qo94Bya4g/videos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Youtube height="25px" width="25px" />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://www.instagram.com/getmaxeddd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram height="25px" width="25px" />
          </a>
          &nbsp;&nbsp;&nbsp;
        </p>
      </div>
    </div>
  </Header>
)
