import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"

import { GlobalStyle, theme } from "../GlobalStyle"
import Header from "../components/partials/Header"
import BlogsWrapper from "../components/BlogsWrapper"
import Footer from "../components/partials/Footer"

import favicon16 from "../images/favicon16.png"
import favicon32 from "../images/favicon32.png"
import favicon64 from "../images/favicon64.png"

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1150px 1fr;

    @media (max-width: 1200px) {
        display: block;
    }
`

export default () => {
    const windowGlobal = typeof window !== "undefined" && window
    const [isScrollingUp, setIsScrollingUp] = useState(false)

    const scrollToTop = () => {
        setIsScrollingUp(true)
        windowGlobal.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
        setTimeout(() => setIsScrollingUp(false), 1500)
    }

    return (
        <ThemeProvider theme={theme}>
            <>
                <Helmet
                    title="getMaxed()"
                    meta={[
                        {
                            name: "description",
                            content: "Max Astapenko, Full Stack Web Developer",
                        },
                        {
                            name: "keywords",
                            content: "frontend, backend, fullstack, developer",
                        },
                    ]}
                    link={[
                        {
                            rel: "icon",
                            type: "image/png",
                            sizes: "16x16",
                            href: `${favicon16}`,
                        },
                        {
                            rel: "icon",
                            type: "image/png",
                            sizes: "32x32",
                            href: `${favicon32}`,
                        },
                        {
                            rel: "shortcut icon",
                            type: "image/png",
                            href: `${favicon64}`,
                        },
                    ]}
                />
                <Container>
                    <Header />
                    <BlogsWrapper
                        scrollToTop={scrollToTop}
                        isScrollingUp={isScrollingUp}
                    />
                    <Footer scrollToTop={scrollToTop} />
                    <GlobalStyle />
                </Container>
            </>
        </ThemeProvider>
    )
}
