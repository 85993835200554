import React from "react"
import styled from "styled-components"

import { theme } from "../../GlobalStyle"
import photo from "../../images/photo.png"
import Github from "./../svg/Github.js"
import Linkedin from "./../svg/Linkedin.js"
import Twitter from "./../svg/Twitter.js"
import Youtube from "./../svg/Youtube.js"
import Instagram from "./../svg/Instagram.js"
import UpArrow from "./../svg/UpArrow.js"

const Footer = styled.footer`
    display: grid;
    grid-template-columns: 1fr 1150px 1fr;
    height: 30rem;
    background-color: ${theme.colors.mainDarker};
    color: ${theme.colors.maroon};
    font-size: 3.8rem;
    margin-bottom: 4rem;
    grid-column: 1/4;

    @media (max-width: 1200px) {
        /* ~> poxody, ne nada */
        grid-template-columns: none;
        height: auto;
    }

    @media (max-width: 560px) {
        font-size: 2.8rem;
    }

    .left {
        grid-column: 1/2;
        background-color: ${theme.colors.main};

        @media (max-width: 1200px) {
            /* ~> poxody, ne nada */
            grid-column: none;
        }
    }

    .center {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column: 2/3;
        /* ~> 12 instead : grid-template-rows: repeat(6, 1fr); */

        @media (max-width: 1200px) {
            grid-column: 1/2;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
    }

    .right {
        grid-column: 3/4;
        background-color: ${theme.colors.main};
    }

    p {
        text-align: center;
        /* padding: 0 5rem; */
    }

    .line1 {
        grid-column: 1/4;
        grid-row: 2/3;

        @media (max-width: 1200px) {
            grid-column: 1/2;
            grid-row: 1/2;
            margin-top: 2rem;
            padding: 0 10.5rem;
        }
    }

    .line2 {
        grid-column: 1/4;
        grid-row: 3/4;

        @media (max-width: 1200px) {
            grid-column: 1/2;
            grid-row: 2/3;
        }
    }

    .line3 {
        grid-column: 1/4;
        grid-row: 4/5;

        @media (max-width: 1200px) {
            grid-column: 1/2;
            grid-row: 3/4;
        }
    }

    .icon {
        grid-column: 4/5;
        grid-row: 2/6;
        position: relative;
        /* position: static; */
        bottom: 2rem;
        height: auto;
        margin: 0 auto;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 1200px) {
            position: static;
            grid-column: 1/2;
            grid-row: 4/5;
            bottom: 0;
            margin-top: 1.1rem;
            margin-bottom: 1rem;
        }
    }
`
// ~> up arrow : make on hover
export default ({ scrollToTop }) => {
    // let interval = null

    // const scrollStep = () => {
    //   if (window.pageYOffset === 0) {
    //     clearInterval(interval)
    //   }

    //   window.scroll(0, window.pageYOffset - 200)
    // }

    // const scrollToTop = () => {
    //   interval = setInterval(scrollStep, 8)
    // }

    // const scrollToTop = () => {
    //   window.scroll({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   })
    // }

    return (
        <Footer>
            <div className="center">
                <p className="line1">You have reached the end of the blog</p>
                <p className="line2">
                    <strong>Thanks for visiting :)</strong>
                </p>
                <p className="line3">
                    &nbsp;&nbsp;
                    <a
                        href="https://www.linkedin.com/in/max-astapenko/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Linkedin height="25px" width="25px" />
                    </a>
                    &nbsp;&nbsp;
                    <a
                        href="https://github.com/getMaxed"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Github height="25px" width="25px" />
                    </a>
                    &nbsp;&nbsp;
                    <a
                        href="https://twitter.com/getmaxeddd"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Twitter height="25px" width="25px" />
                    </a>
                    &nbsp;&nbsp;
                    <a
                        href="https://www.youtube.com/channel/UCMS8XLydNkkS10qo94Bya4g/videos"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Youtube height="25px" width="25px" />
                    </a>
                    &nbsp;&nbsp;
                    <a
                        href="https://www.instagram.com/getmaxeddd/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Instagram height="25px" width="25px" />
                    </a>
                    &nbsp;&nbsp;
                </p>
                <div className="icon" onClick={scrollToTop}>
                    <UpArrow width="200px" height="200px" />
                </div>
            </div>
        </Footer>
    )
}
