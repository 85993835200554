import React, { useState } from "react"

// ~> hover effect here
// ....aaaaaaaaaaaa

export default ({ width, height }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(!isHovered)
  const handleMouseLeave = () => setIsHovered(!isHovered)

  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 582.207 582.207"
      style={{ enableBackground: "new 0 0 582.207 582.207" }}
      xmlSpace="preserve"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <g>
        <g>
          <path
            //   fill="#ce7375"
            fill={isHovered ? "#ce7375" : "#7eaaa1"}
            d="M291.104,0C130.589,0,0,130.588,0,291.104c0,160.516,130.589,291.104,291.104,291.104s291.103-130.588,291.103-291.104
			C582.207,130.588,451.619,0,291.104,0z M291.104,521.008c-126.77,0-229.904-103.141-229.904-229.904
			C61.2,164.34,164.334,61.2,291.104,61.2c126.769,0,229.904,103.141,229.904,229.904
			C521.008,417.867,417.873,521.008,291.104,521.008z"
          />
          <path
            fill={isHovered ? "#7eaaa1" : "#ce7375"}
            d="M311.104,97.596c-11.047-11.047-28.953-11.047-40,0L130.503,238.203c-11.046,11.046-7.338,20,8.28,20h53.672v198.893
			c0,6.25,5.067,11.316,11.316,11.316h174.664c6.25,0,11.316-5.066,11.316-11.316V258.203h53.672c15.619,0,19.328-8.954,8.281-20
			L311.104,97.596z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}
