import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { theme } from "../../GlobalStyle"

const Blog = styled.div`
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 3rem;
    padding-top: 0;
    font-size: 1.8rem;

    @media (max-width: 560px) {
        font-size: 1.5rem;
    }

    @media (max-width: 410px) {
        font-size: 1.4rem;
    }

    @media (max-width: 380px) {
        font-size: 1.3rem;
    }

    @media (max-width: 350px) {
        font-size: 1.2rem;
    }

    p {
        max-width: 68rem;
        margin-top: 2.7rem;
        margin-left: 5.2rem;
        line-height: 2.8rem;
        font-weight: 200;
        font-family: "Lora", "Georgia", serif;

        @media (max-width: 830px) {
            margin-top: 2.3rem;
            margin-left: 3.2rem;
        }

        @media (max-width: 750px) {
            margin-top: 2.1rem;
            margin-left: 2.2rem;
        }

        @media (max-width: 710px) {
            margin-top: 1.9rem;
            margin-left: 1.3rem;
        }

        @media (max-width: 675px) {
            margin-top: 1.7rem;
            margin-left: 0.8rem;
        }

        @media (max-width: 560px) {
            margin-left: 0.6rem;
        }

        @media (max-width: 490px) {
            margin-left: 0.3rem;
        }

        @media (max-width: 340px) {
            line-height: 2.4rem;
        }
    }

    .firstP {
        margin-top: 3.7rem;

        @media (max-width: 830px) {
            margin-top: 2.9rem;
        }

        @media (max-width: 750px) {
            margin-top: 2.5rem;
        }

        @media (max-width: 710px) {
            margin-top: 2.1rem;
        }

        @media (max-width: 675px) {
            margin-top: 1.7rem;
        }
    }

    .center {
        max-width: 750px;
        margin: 0 auto;
    }

    /* VIDEO STYLES */

    .video {
        max-width: 750px;
        max-height: 421.875px;
        margin-top: 2.7rem;
        margin-bottom: 2px;
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }

    .videoWrapper {
        position: relative;
        padding-top: 56.25%;
    }

    img,
    source {
        display: ${props => (props.isClicked ? "none" : "inline")};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border: 0.7rem solid ${theme.colors.main};
    }

    iframe {
        visibility: ${props => (props.isClicked ? "visible" : "hidden")};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .videoButton {
        display: ${props => (props.isClicked ? "none" : "inline")};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: transparent;
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }

    .videoBtnShape {
        fill: ${props => (props.isHovered ? "#ff0000" : "#212121")};
        fill-opacity: ${props => (props.isHovered ? "1" : "0.8")};
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }
`

export default () => {
    const [isHovered, setIsHovered] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    /*
  |--------------------------------------------------------------------------
  | EVENTS
  |--------------------------------------------------------------------------
  */

    const handleMouseEnter = () => setIsHovered(!isHovered)
    const handleMouseLeave = () => setIsHovered(!isHovered)
    const handleClick = () => setIsClicked(!isClicked)

    /*
  |--------------------------------------------------------------------------
  | RENDER
  |--------------------------------------------------------------------------
  */

    return (
        <Blog isHovered={isHovered} isClicked={isClicked}>
            <div className="center">
                <h1>Playing with Vanilla JavaScript: Timers</h1>
                <div
                    className="video"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                    <div className="videoWrapper">
                        <picture>
                            <source
                                srcSet="https://i.ytimg.com/vi_webp/iJ7-3Jb99Gc/maxresdefault.webp"
                                type="image/webp"
                            />
                            <img src="https://i.ytimg.com/vi/iJ7-3Jb99Gc/maxresdefault.jpg" />
                        </picture>
                        <iframe
                            src="https://www.youtube.com/embed/iJ7-3Jb99Gc?autoplay=1"
                            allow="autoplay"
                            // allowFullScreen
                            inlineplay="true"
                        />

                        <button className="videoButton">
                            <svg width="88" height="68" viewBox="0 0 68 48">
                                <path
                                    className="videoBtnShape"
                                    d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                />
                                <path fill="#ffffff" d="M 45,24 27,14 27,34" />
                            </svg>
                        </button>
                    </div>
                </div>
                <p className="firstP">
                    In the browser, timer events, or simply timers, such as{" "}
                    <code>setInterval</code> and <code>setTimeout</code>, are
                    Web APIs, globally available for our JavaScript code.
                    Interesting thing is that both of these methods are not a
                    part of the JavaScript. Along with DOM API, XHR (Ajax),
                    fetch, canvas etc, they are part of aforementioned Web APIs,
                    provided to us by the browser environment. Think about them
                    as separate C++ modules of code, that do their job
                    separately from the main execution stack.
                </p>{" "}
                <p>
                    Imagine our JavaScript engine parses our code and it
                    stumbles upon some <code>setInterval</code>. The callback,
                    passed to a function as a second parameter, is scheduled to
                    be executed after a certain period of time. And who's gonna
                    be responsible for counting the time and then pushing it
                    into the call stack in the due time? You guessed it, a
                    separate from JavaScript engine, Web API. When we retrieve
                    the data from an external source asynchronously using{" "}
                    <code>fetch</code> or <code>XMLHttpRequest</code> - who's
                    doing all that work for us? Same thing, Web APIs.
                    Understanding this process is a key to understanding one of
                    the most important concepts behind JavaScript - event loop.
                    You can watch{" "}
                    <a
                        href="https://www.youtube.com/watch?v=8aGhZQkoFbQ"
                        target="blank"
                    >
                        this great video on Event Loop
                    </a>{" "}
                    if you're interested about what is going on under the hood.
                </p>
                <p>
                    In my video, I don't go into the concepts of timers, I show
                    the practical example of how you can use them. Sure, there
                    is a lot going on with them, I didn't even mention closures
                    that they create, but we can apply timers for the simple and
                    fun DOM manipulations without going into depths of the
                    language. And now that you can see some examples - get
                    creative and come up with something cool :)
                </p>
            </div>
            <hr />
        </Blog>
    )
}
