import React, { useState, useEffect, useRef } from "react"
import styled, { ThemeProvider } from "styled-components"

import Icon from "../components/partials/Icon"

import Blog8 from "../components/blogs/Blog8"
import Blog7 from "../components/blogs/Blog7"
import Blog6 from "../components/blogs/Blog6"
import Blog5 from "../components/blogs/Blog5"
import Blog4 from "../components/blogs/Blog4"
import Blog3 from "../components/blogs/Blog3"
import Blog2 from "../components/blogs/Blog2"
import Blog1 from "../components/blogs/Blog1"

// ~> header/footer

// tgr

const BlogsWrapper = styled.div`
    grid-column: 2/3;
    background-color: white;
    /* display: grid;
  grid-template-columns: 1fr 710px 1fr; */

    h1 {
        text-align: center;

        @media (max-width: 400px) {
            font-size: 1.9em;
        }

        /* @media (max-width: 380px) {
      font-size: 1.8em;
    } */
    }

    /* this is the original one */

    /* p {
    max-width: 68rem;
    margin-top: 2.7rem;
    margin-left: 5.2rem;
    line-height: 2.8rem;
    font-weight: 200;
    font-family: "Lora", "Georgia", serif;
  } */

    /* .firstP {
    margin-top: 3.7rem;
  } */

    hr {
        margin-top: 6rem;
    }

    a {
        color: #cf6a68;
        /* text-decoration: none; */

        &:hover {
            /* text-decoration: underline; */
            text-decoration: none;
        }
    }

    code {
        font-family: Monaco, Consolas, "Liberation Mono", "Courier New",
            monospace;
        color: rgb(34, 36, 38);
        font-size: 1.5rem;
        padding: 2px 4px;
        background: #fffae0;
        /* background: rgb(244, 243, 250); */
        border-radius: 3px;

        @media (max-width: 560px) {
            font-size: 1.3rem;
        }

        @media (max-width: 410px) {
            font-size: 1.2rem;
        }

        @media (max-width: 380px) {
            font-size: 1.1rem;
        }

        @media (max-width: 350px) {
            font-size: 1rem;
        }
    }
`

const Container = styled.div`
    grid-column: 2/3;
`

export default ({ scrollToTop, isScrollingUp }) => {
    const windowGlobal = typeof window !== "undefined" && window
    const [wrapperHeight, setWrapperHeight] = useState(null)
    const wr = useRef(null)

    setTimeout(() => {
        setWrapperHeight(
            windowGlobal.getComputedStyle(wr.current).getPropertyValue("height")
        )
        // console.log(wrapperHeight)
        // console.log(wr.current)
    }, 700)

    return (
        <BlogsWrapper ref={wr}>
            <Container>
                <Blog8 />
                <Blog7 />
                <Blog6 />
                <Blog5 />
                <Blog4 />
                <Blog3 />
                <Blog2 />
                <Blog1 />
                <Icon
                    wrapperHeight={wrapperHeight}
                    scrollToTop={scrollToTop}
                    isScrollingUp={isScrollingUp}
                />
            </Container>
        </BlogsWrapper>
    )
}
