import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { theme } from "../../GlobalStyle"

const Blog = styled.div`
    margin: 0 auto;
    margin-bottom: 1rem;
    padding: 3rem;
    padding-top: 0;
    font-size: 1.8rem;

    @media (max-width: 560px) {
        font-size: 1.5rem;
    }

    @media (max-width: 410px) {
        font-size: 1.4rem;
    }

    @media (max-width: 380px) {
        font-size: 1.3rem;
    }

    @media (max-width: 350px) {
        font-size: 1.2rem;
    }

    p {
        max-width: 68rem;
        margin-top: 2.7rem;
        margin-left: 5.2rem;
        line-height: 2.8rem;
        font-weight: 200;
        font-family: "Lora", "Georgia", serif;

        @media (max-width: 830px) {
            margin-top: 2.3rem;
            margin-left: 3.2rem;
        }

        @media (max-width: 750px) {
            margin-top: 2.1rem;
            margin-left: 2.2rem;
        }

        @media (max-width: 710px) {
            margin-top: 1.9rem;
            margin-left: 1.3rem;
        }

        @media (max-width: 675px) {
            margin-top: 1.7rem;
            margin-left: 0.8rem;
        }

        @media (max-width: 560px) {
            margin-left: 0.6rem;
        }

        @media (max-width: 490px) {
            margin-left: 0.3rem;
        }

        @media (max-width: 340px) {
            line-height: 2.4rem;
        }
    }

    .firstP {
        margin-top: 3.7rem;

        @media (max-width: 830px) {
            margin-top: 2.9rem;
        }

        @media (max-width: 750px) {
            margin-top: 2.5rem;
        }

        @media (max-width: 710px) {
            margin-top: 2.1rem;
        }

        @media (max-width: 675px) {
            margin-top: 1.7rem;
        }
    }

    .center {
        max-width: 750px;
        margin: 0 auto;
    }

    /* VIDEO STYLES */

    .video {
        max-width: 750px;
        max-height: 421.875px;
        margin-top: 2.7rem;
        margin-bottom: 2px;
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }

    .videoWrapper {
        position: relative;
        padding-top: 56.25%;
    }

    img,
    source {
        display: ${props => (props.isClicked ? "none" : "inline")};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border: 0.7rem solid ${theme.colors.main};
    }

    iframe {
        visibility: ${props => (props.isClicked ? "visible" : "hidden")};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .videoButton {
        display: ${props => (props.isClicked ? "none" : "inline")};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: transparent;
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }

    .videoBtnShape {
        fill: ${props => (props.isHovered ? "#ff0000" : "#212121")};
        fill-opacity: ${props => (props.isHovered ? "1" : "0.8")};
        cursor: ${props => (props.isHovered ? "pointer" : "auto")};
    }
`

export default () => {
    const [isHovered, setIsHovered] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    /*
  |--------------------------------------------------------------------------
  | EVENTS
  |--------------------------------------------------------------------------
  */

    const handleMouseEnter = () => setIsHovered(!isHovered)
    const handleMouseLeave = () => setIsHovered(!isHovered)
    const handleClick = () => setIsClicked(!isClicked)

    /*
  |--------------------------------------------------------------------------
  | RENDER
  |--------------------------------------------------------------------------
  */

    return (
        <Blog isHovered={isHovered} isClicked={isClicked}>
            <div className="center">
                <h1>Sorting Algorithms in JS: Selection Sort</h1>
                <div
                    className="video"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                    <div className="videoWrapper">
                        <picture>
                            <source
                                srcSet="https://i.ytimg.com/vi_webp/Tb47pqh5U58/maxresdefault.webp"
                                type="image/webp"
                            />
                            <img src="https://i.ytimg.com/vi/Tb47pqh5U58/maxresdefault.jpg" />
                        </picture>
                        <iframe
                            src="https://www.youtube.com/embed/Tb47pqh5U58?autoplay=1"
                            allow="autoplay"
                            // allowFullScreen
                            inlineplay="true"
                        />

                        <button className="videoButton">
                            <svg width="88" height="68" viewBox="0 0 68 48">
                                <path
                                    className="videoBtnShape"
                                    d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                />
                                <path fill="#ffffff" d="M 45,24 27,14 27,34" />
                            </svg>
                        </button>
                    </div>
                </div>
                <p className="firstP">
                    Selection sort is the probaby the second simplest sorting
                    algorithm out there. And it's not very efficient as well -
                    you definitely don't wanna use it for large amounts of data.
                    But understanding this algorithm is another step to
                    understanding more faster and more efficient ones, and it's
                    a very logical progression from the Bubble Sort, because
                    these 2 algorithms are similar to one another.
                </p>
                <p>
                    Selection Sort is often called a "reversed Bubble Sort".
                    Just like Bubble Sort, it uses nested <code>for loop</code>{" "}
                    and <code>swipe</code> function, but after each iteration,
                    instead of placing the largest number to the very end of
                    unsorted part of the array, as the result of a so-called
                    "bubbling", we select the smallest number and place it in
                    the very beginning of the unsorted part of the array. Also,
                    instead of swapping elements each time we go through
                    numbers, comparing one to another, we just keep the smallest
                    number of the each iteration in memory, and then do the
                    swapping, once iteration is over. It is a considerable win
                    over a Bubble Sort. More about the differences between the 2
                    algorithms you can find in{" "}
                    <a href="https://techdifferences.com/difference-between-bubble-sort-and-selection-sort.html">
                        this article
                    </a>
                    .
                </p>
                {/* <p>
          You can read about differences between Bubble Sort and Selection Sort
          in{" "}
          <a href="https://techdifferences.com/difference-between-bubble-sort-and-selection-sort.html">
            this article
          </a>
          . And even though Selection Sort has better performance benefits
          compared to Bubble Sort, it's still considered a
        </p> */}
            </div>
            <hr />
        </Blog>
    )
}
