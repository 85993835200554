import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Icon = styled.div`
    display: inline-block;
    visibility: ${props => props.visibility};
    z-index: 99;
    position: fixed;
    left: ${props => props.leftValue}px;
    bottom: 125px;
    padding: 8px 8px 6px;
    border: 1px solid black;
    transform: translateX(-20%);
    opacity: 0.6;
    transition: all 0.3s ease;

    .arrow {
        transition: all 0.3s ease;
        width: 40px;
        height: 40px;
        visibility: ${props => props.visibility};
    }

    .btn-1 {
        fill: #ff0000;
    }

    &:hover {
        cursor: pointer;
        transform: translateX(-20%);
        opacity: 1;
        border: 1px solid white;
        transition: all 0.3s ease;

        .arrow {
            bottom: 2px;
            position: relative;
        }
    }
`

export default ({ wrapperHeight, scrollToTop, isScrollingUp }) => {
    const windowGlobal = typeof window !== "undefined" && window
    const [windowWidth, setWindowWidth] = useState(windowGlobal.innerWidth)
    const [scrollY, setScrollY] = useState(null)

    const handleScroll = () => setScrollY(windowGlobal.scrollY)
    const handleResize = () => setWindowWidth(windowGlobal.innerWidth)

    useEffect(() => {
        windowGlobal.addEventListener("resize", handleResize)
        return () => {
            windowGlobal.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        windowGlobal.addEventListener("scroll", handleScroll)
        return () => {
            windowGlobal.removeEventListener("resize", handleScroll)
        }
    })

    const SZ = 8
    // const leftValue = (windowWidth - 1150) / 2 - SZ
    const leftValue = 1150 + (windowWidth - 1150) / 2

    const visibility = isScrollingUp
        ? "hidden"
        : scrollY > 1700 &&
          scrollY < parseInt(wrapperHeight) - 2500 &&
          windowWidth > 1300
        ? "visible"
        : "hidden"

    return (
        <Icon
            leftValue={leftValue}
            visibility={visibility}
            onClick={scrollToTop}
        >
            <svg
                className="arrow"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 284.929 284.929"
                style={{ enableBackground: ":new 0 0 284.929 284.929" }}
                xmlSpace="preserve"
            >
                <g>
                    <g>
                        <path
                            className="btn-1"
                            d="M17.128,167.872c1.903,1.902,4.093,2.854,6.567,2.854c2.474,0,4.664-0.952,6.567-2.854L142.466,55.666l112.208,112.206
            c1.902,1.902,4.093,2.854,6.563,2.854c2.478,0,4.668-0.952,6.57-2.854l14.274-14.277c1.902-1.902,2.847-4.093,2.847-6.563
            c0-2.475-0.951-4.665-2.847-6.567L149.028,7.419c-1.901-1.906-4.088-2.853-6.562-2.853s-4.665,0.95-6.567,2.853L2.856,140.464
            C0.95,142.367,0,144.554,0,147.034c0,2.468,0.953,4.658,2.856,6.561L17.128,167.872z"
                        />
                        <path
                            className="btn-2"
                            d="M149.028,117.055c-1.901-1.906-4.088-2.856-6.562-2.856s-4.665,0.953-6.567,2.856L2.856,250.1
                        C0.95,252.003,0,254.192,0,256.67c0,2.472,0.953,4.661,2.856,6.564l14.272,14.276c1.903,1.903,4.093,2.848,6.567,2.848
                        c2.474,0,4.664-0.951,6.567-2.848l112.204-112.209l112.208,112.209c1.902,1.903,4.093,2.852,6.563,2.852
                        c2.478,0,4.668-0.948,6.57-2.852l14.274-14.276c1.902-1.903,2.847-4.093,2.847-6.564c0-2.478-0.951-4.667-2.847-6.57
                        L149.028,117.055z"
                        />
                    </g>
                </g>
            </svg>
        </Icon>
    )
}
