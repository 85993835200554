import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

@import url('https://fonts.googleapis.com/css?family=Lora&display=swap');

html {
    box-sizing: border-box;
    font-size: 62.5%;  
    }    
body {
    font-family: 'Lora', Arial, sans-serif;
}    
`

export const theme = {
  colors: {
    main: "#e1eef3",
    mainDarker: "#d0e7ef",
    maroon: "#ce7375",
  },
}
